// @flow
import React from 'react';
import { Map } from 'immutable';
import {
    Button,
    TextField,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import { compose } from 'recompose';
import Select from 'react-select';
import {
    getLanguages,
    parseClassesForAutocompleteView,
} from '../../../lib/utils';
import { withTranslation } from 'react-i18next';
import AutoCompleteView from '../../AutoCompleteView';
import type { TEmployee } from '../../../reducers/employeesReducer';
import classes from './EmployeeEditCard.module.scss';
import colors from '../../../lib/themes/theme.scss';
import {connect} from "react-redux";

type Props = {
    employee?: TEmployee,
    onEdit: (employee?: TEmployee) => mixed,
    onSave: (employee: TEmployeem, onSucces: () => mixed) => mixed,
    changeFactuurEmail: () => mixed,
    goBack: () => mixed,
    clearDiff: () => mixed,
    display: boolean,
};

type State = {
    passwordConfirm: string,
    password: string,
    errors: Map<string, string>,
    showPasswordField: boolean,
    saving: boolean,
    factuur: string,
    languageValue: null,
};

// For react-select
const customStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? colors.primaryColor : "white",
            "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};

const mapStateToProps = (store) => {
    const employees = store.entities.employees;

    return {
        employees: employees.allIds.map((id) => employees.byId[id]),
    };
};


class EmployeeEditCard extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        passwordConfirm: '',
        password: '',
        saving: false,
        errors: new Map(),
        showPasswordField: false,
        factuur: '',
    };

    handleFirstnameChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                firstname: event.target.value,
            })
        );

        this.setState({
            errors: this.state.errors.remove('firstname'),
        });
    };

    handleMiddlenameChange = (event: any) => {
        if (event.target.value.length > 50) {
            return;
        }
        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                middlename: event.target.value,
            })
        );
    };

    handleLastnameChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                lastname: event.target.value,
            })
        );

        this.setState({
            errors: this.state.errors.remove('lastname'),
        });
    };

    handleUsernameChange = (event: any) => {

        if (event.target.value.length > 100) {
            return;
        }

        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                username: event.target.value,
            })
        );

        this.setState({
            errors: this.state.errors.remove('username'),
        });
    };

    handleFactuurChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.setState({
            factuur: event.target.value,
        });
    };

    setExternalUsername = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                username: this.props.employee.externalusername,
            })
        );

        this.setState({
            errors: this.state.errors.remove('username'),
        });
    };

    handlePhonenumberChange = (event: any) => {
        if (event.target.value.length > 30) {
            return;
        }

        let regEx = RegExp('^[0-9]+$');
        if (!regEx.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '');
        }

        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                phonenumber: event.target.value,
            })
        );
    };

    handlePasswordChange = (event: any) => {
        this.setState({
            password: event.target.value,
            errors: this.state.errors.remove('password'),
        });
    };

    handlePasswordConfirmChange = (event: any) => {
        this.setState({
            passwordConfirm: event.target.value,
            errors: this.state.errors.remove('passwordconfirm'),
        });
    };

    handelRoleChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                roles: [event.target.value],
            })
        );
        if (event.target.value === 'COORDINATOR') {
            this.showPasswordField();
        } else {
            this.hidePasswordField();
        }
    };

    cancelEdit = () => {
        // this.props.onEdit(undefined);
        this.props.goBack();
    };

    showPasswordField = () => {
        this.setState({ showPasswordField: true });
    };

    hidePasswordField = () => {
        this.setState({ showPasswordField: false });
    };

    doPasswordValidation = (errors: Map<string, string>) => {
        const { t } = this.props;
        if (this.state.showPasswordField) {
            let passwordEmpty = false;
            if (!this.state.password || this.state.password === '') {
                errors = errors.set('password', t('This field is required'));
                passwordEmpty = true;
            }

            if (
                !this.state.passwordConfirm ||
                this.state.passwordConfirm === ''
            ) {
                errors = errors.set(
                    'passwordconfirm',
                    t('This field is required')
                );
                passwordEmpty = true;
            }

            let passwordOk = false;
            if (
                !passwordEmpty &&
                this.state.password !== this.state.passwordConfirm
            ) {
                errors = errors.set('password', t('Password mismatch'));
                errors = errors.set('passwordconfirm', t('Password mismatch'));
            } else if (!passwordEmpty) {
                passwordOk = true;
            }

            if (passwordOk && this.state.password.length < 8) {
                errors = errors.set('password', t('Password tooshort'));
                errors = errors.set('passwordconfirm', t('Password tooshort'));
            }
        }
        return errors;
    };

    saveEmployee = () => {
        const { t } = this.props;
        this.setState({
            saving: true,
        });
        let errors: Map<string, string> = new Map();

        let empl = this.props.employee;

        console.log('XXX saveEmployee: empl = ', empl);
        if (empl.accountEnabled) {
            // Account was deleted, strip all ":D" from email
            empl.username = empl.username.replaceAll(":D","");
            console.log('XXX Undeleting set username: ', empl.username);
        }


        // When adding a new empl check the current list for this email address
        if (empl) {
            const emailFound = this.props.employees.find(user => user.username === empl.username);
            console.log('XXX emailFound: ', emailFound);
            if (emailFound && emailFound.username && emailFound.id !== empl.id) {
                errors = errors.set('username', t('Duplicate email address found in your list.'));
            }
        }

        if (!this.props.employee) {
            //TODO error?
            return;
        }


        if (!empl.firstname || empl.firstname === '') {
            errors = errors.set('firstname', t('This field is required'));
        }

        if (!empl.lastname || empl.lastname === '') {
            errors = errors.set('lastname', t('This field is required'));
        }


        if (
            //eslint-disable-next-line
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                empl.username
            )
        ) {
            errors = errors.set('username', t('Email invalid'));
        }

        if (!empl.username || empl.username === '') {
            errors = errors.set('username', t('This field is required'));
        }

        //Only validate password if employee is new, so when id is undefined
        if (!empl.id) errors = this.doPasswordValidation(errors);

        //No errors
        if (errors.isEmpty()) {
            this.setState(Object.assign({}, this.state, { errors: errors }));
            if (this.props.abonneehouder) {
                this.props.changeFactuurEmail(this.state.factuur);
            }
            this.props.onSave(
                Object.assign({}, this.props.employee, {
                    password: this.state.password,
                    type: this.props.display,
                }),
                (this.onSucces = () => {
                    this.props.onEdit(undefined);
                    this.setState({
                        saving: false,
                    });
                })
            );
        } else {
            this.setState({
                errors,
                saving: false,
            });
        }
    };

    componentDidMount() {
        if (this.props.abonneehouder && this.props.factuurEmail) {
            this.setState({
                factuur: this.props.factuurEmail,
            });
        }
    }

    isKam = (empl) => {
        if (empl && empl.roles) {
            const roles = empl.roles;
            return roles.includes('COORDINATOR');
        }
        return false;
    };

    handleChangeLanguage = (language) => {
        this.setState({ languageValue: language });

        this.props.onEdit(
            Object.assign({}, this.props.employee, {
                language: language.value,
            })
        );
    };

    render() {
        //Als we geen employee hebben niets renderen
        const languages = getLanguages(true);
        if (!this.props.employee) {
            return <div />;
        }

        const { t } = this.props;

        const employee = this.props.employee;

        const { languageValue } = this.state;

        const errors = this.state.errors;

        let factuur = this.state.factuur;

        if (employee.username == null && employee.extern === true) {
            this.setExternalUsername();
        }

        return (
            <div>
                <DialogContent className={classes.dialog}>
                    <DialogContentText className={classes.text}>
                        {this.props.abonneehouder
                            ? t('Edit subscription holder data')
                            : t('Enter data new employee')}
                    </DialogContentText>

                    <TextField
                        id="firstname"
                        variant="standard"
                        className={classes.voornaam}
                        label={t('First name')}
                        value={employee.firstname}
                        error={errors.get('firstname') != null}
                        required
                        autoFocus={true}
                        helperText={errors.get('firstname')}
                        onChange={this.handleFirstnameChange}
                    />

                    <TextField
                        id="middlename"
                        variant="standard"
                        className={classes.tussenvoegsel}
                        label={t('Insertion')}
                        value={employee.middlename}
                        helperText={errors.get('middlename')}
                        onChange={this.handleMiddlenameChange}
                    />

                    <TextField
                        id="lastname"
                        variant="standard"
                        className={classes.achternaam}
                        label={t('Last name')}
                        error={errors.get('lastname') != null}
                        required
                        helperText={errors.get('lastname')}
                        value={employee.lastname}
                        onChange={this.handleLastnameChange}
                    />

                    <div className={classes.languageSelect}>
                        <DialogContentText>
                            {t('Select language')}.
                        </DialogContentText>
                        <Select
                            styles={customStyles}
                            fullWidth
                            inputComponent={(props) => (
                                <AutoCompleteView
                                    {...props}
                                    classes={parseClassesForAutocompleteView(
                                        props
                                    )}
                                />
                            )}
                            onChange={this.handleChangeLanguage}
                            placeholder={t('Language')}
                            defaultValue={{ label: t('translated-nl'), value: 'nl'}}
                            id="language-single-select"
                            value={languageValue}
                            options={ languages.map((language) => ({
                                value: language,
                                label: t(`translated-${language}`),
                            }))
                        }
                        />
                    </div>

                    <TextField
                        id="phonenumber"
                        variant="standard"
                        className={classes.telefoon}
                        label={t('Phone number')}
                        value={
                            employee.phonenumber
                                ? employee.phonenumber.replace(
                                      /([A-Z]|[a-z]|\s)/g,
                                      ''
                                  )
                                : undefined
                        }
                        error={errors.get('phonenumber') != null}
                        onChange={this.handlePhonenumberChange}
                    />

                    <TextField
                        id="username"
                        variant="standard"
                        className={classes.email}
                        label={t('Emailadres')}
                        value={
                            employee.username
                                ? employee.username.replace(/\s/g, '')
                                : undefined
                        }
                        error={errors.get('username') != null}
                        required
                        helperText={errors.get('username')}
                        onChange={this.handleUsernameChange}
                    />

                    {this.props.abonneehouder && (
                        <TextField
                            id="factuur"
                            variant="standard"
                            className={classes.email}
                            label={t('Invoice email')}
                            value={
                                factuur ? factuur.replace(/\s/g, '') : undefined
                            }
                            helperText={errors.get('factuur')}
                            onChange={this.handleFactuurChange}
                        />
                    )}

                    <DialogContentText
                        className={classes.text}
                        style={{ fontSize: '14px' }}
                    >
                        {t('* marked fields are required')}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        className="secondaryButton"
                        onClick={this.cancelEdit}
                    >
                        {t('Cancel')}
                    </Button>
                    { !this.props.employee.accountEnabled && (
                        <Button
                            className="secondaryButton"
                            onClick={this.saveEmployee}
                            disabled={this.state.saving}
                        >
                            {employee.id ? t('Save') : t('Add')}
                        </Button>
                    )}
                    {this.props.employee.accountEnabled && this.props.isAdmin && (
                        <Button
                            className="secondaryButton"
                            onClick={this.saveEmployee}
                            disabled={this.state.saving}
                        >
                            { t('Undelete') }
                        </Button>

                    )}

                </DialogActions>
            </div>
        );
    }
}


export default compose(
    connect(mapStateToProps, null),
    withTranslation()
)(EmployeeEditCard);
