// @flow
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, ListItem } from '@mui/material';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import { fetchToolboxByToken, sendResultExtern } from '../../../actions';
import BlockPreview from '../../ToolboxPreview/BlockPreview';

import './ToolboxExtern.css';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { fetchPDF } from '../../../lib/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckboxUncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckboxCheckedIcon from '@mui/icons-material/CheckBox';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import moment from 'moment';
import { compose } from 'recompose';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import classes from './ToolboxExtern.module.scss';

type Props = {
    toolboxId: string,
    toolbox: Array<any>,
};

type State = {
    feedbackText: string,
    questionsFinished: any,
    downloadPDF: boolean,
};


const mapStateToProps = (store, props) => {
    return {
        externalToolboxAssign: store.external.externalToolboxAssign,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchToolboxByToken: () => {
            dispatch(fetchToolboxByToken(ownProps.match.params.toolboxId));
        },
        sendResults: (result) => {
            dispatch(sendResultExtern(ownProps.match.params.toolboxId, result));
        },
    };
};

function checkAnswers(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (var i = arr1.length; i--; ) {
        if (arr1[i] !== arr2[i]) return false;
    }

    return true;
}

class ToolboxExtern extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        tab: 0,
        questionsFinished: -1,
        selectedAnswers: [],
        buttonText: i18n.t('Answer'),
        buttonDisabled: true,
        buttonNextQuestion: false,
        outcomeText: '',
        feedbackText: '',
        downloadPDF: true,
    };

    componentDidMount() {
        this.props.fetchToolboxByToken();
    }

    handleFormChange = (key) => (event) => {
        if (event.target.value.length < 255) {
            this.setState({
                feedbackText: event.target.value,
            });
        }
    };

    handleCheckPDF = (key) => (event) => {
        this.setState({
            downloadPDF: event.target.checked,
        });
    };

    toSelfTest = () => {
        const { t } = this.props;
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (localStorage.getItem('answers' + this.props.match.params.toolboxId)) {
            this.setState({
                selectedAnswers: JSON.parse(localStorage.getItem('answers' + this.props.match.params.toolboxId)),
            });
        }
        if (localStorage.getItem('questionsfinished' + this.props.match.params.toolboxId)) {
            this.setState({
                questionsFinished: JSON.parse(localStorage.getItem('questionsfinished' + this.props.match.params.toolboxId)),
            });
        }
        // if (localStorage.getItem('tabscounter' + this.props.match.params.toolboxId)) {
        //     this.setState({
        //         tab: JSON.parse(localStorage.getItem('tabscounter' + this.props.match.params.toolboxId)),
        //     });
        // }
        if (localStorage.getItem('finished' + this.props.match.params.toolboxId) &&
            localStorage.getItem('finished' + this.props.match.params.toolboxId) === 'true') {
            this.setState({
                buttonText: t('TO SELF-TEST'),
                buttonNextQuestion: true,
                buttonDisabled: false,
            });
        }
        this.handleTabChange(null, this.state.tab);
    };

    sendResults = () => {
        let tlbxId = this.props.externalToolboxAssign.toolbox.toolboxId;

        localStorage.removeItem('answers' + tlbxId);
        localStorage.removeItem('questionsfinished' + tlbxId);
        localStorage.removeItem('tabscounter' + tlbxId);
        localStorage.removeItem('finished' + tlbxId);

        let answers = [];
        this.props.externalToolboxAssign.toolbox.toolbox.questions.map(
            (question, index) =>
                answers.push({
                    id: question.id,
                    answers: this.state.selectedAnswers[index],
                    correct: checkAnswers(this.state.selectedAnswers[index],question.correctAnswers),
                })
        );
        let results;
        if (this.state.feedbackText.length > 0) {
            results = {
                answers: answers,
                complete: true,
                feedback: this.state.feedbackText,
            };
        } else {
            results = { answers: answers, complete: true };
        }
        this.props.sendResults(results);
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });
        if (this.state.downloadPDF === true) {
            this.downloadPDF();
        }
    };

    downloadPDF = () => {
        const { externalToolboxAssign, toolbox } = this.props;

        let pdfToolbox = Object.assign({}, toolbox);

        if (externalToolboxAssign) {
            pdfToolbox = Object.assign({}, externalToolboxAssign);
        }

        pdfToolbox.showResults = false;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOX',
            options: {},
            data: [],
            info: {
                toolbox: pdfToolbox.toolbox,
                externalToolboxAssign,
                results: this.state.selectedAnswers,
                nosectors: true,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);

                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download',pdfToolbox.toolbox.title + ' v' + pdfToolbox.toolbox.version + '.pdf');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    handleTabChange = (event: any, value: number) => {
        const { t } = this.props;
        const { questionsFinished } = this.state;
        this.setState(
            {
                tab: value,
            },
            () => {
                const pos = this.state.selectedAnswers[this.state.tab];
                if (pos || pos === 0) {
                    if (!(this.state.questionsFinished >= this.state.tab)) {
                        //Question not finished

                        this.setState({
                            outcomeText: 'Question not finished',
                        });
                    } else {
                        //Question finished
                        const currentQuestion = this.props.externalToolboxAssign.toolbox.toolbox.questions[this.state.tab];
                        const question = this.props.externalToolboxAssign.toolbox.toolbox.questions[this.state.tab].correctAnswers;
                        const selected = this.state.selectedAnswers[this.state.tab];
                        let isAllCorrect = true;

                        for (let i = 0;currentQuestion.possibleAnswers.length !== i; i++) {
                            this.refs['divAnswerBoxChecked' + i].style.visibility = 'hidden';
                            this.refs['divAnswerBox' + i].style.visibility = 'hidden';
                        }

                        for (let i = 0; selected.length !== i; i++) {
                            this.refs['divAnswer' + selected[i]].style.backgroundColor = '#D7D7D7';
                        }

                        for (let i = 0;i !== currentQuestion.possibleAnswers.length;i++) {
                            this.refs['divAnswerIcon' + i].style.visibility = 'visible';
                        }

                        for (let i = 0; i < selected.length; i++) {
                            if (currentQuestion.type === 'singleResponse') {
                                this.refs['divAnswer' + selected].style.backgroundColor = '#D7D7D7';
                                if (selected[i] !== question[i]) {
                                    isAllCorrect = false;
                                }
                            } else {
                                this.refs['divAnswer' + selected[i]].style.backgroundColor = '#D7D7D7';
                                if (selected !== question) {
                                    isAllCorrect = false;
                                }
                            }
                        }

                        if (isAllCorrect) {
                            this.refs['outcomeText'].style.color = 'green';
                            this.setState({
                                outcomeText: t('Your answer is correct'),
                            });
                        } else {
                            let correctAnswers = '';
                            question.forEach((element) => {
                                correctAnswers += String.fromCharCode(65 + element) + ', ';
                            });

                            this.refs['outcomeText'].style.color = 'red';
                            if (correctAnswers.length > 3) {
                                this.setState({
                                    outcomeText: t('Your answer is incorrect. The correct answers are:') + correctAnswers.slice(0, -2),
                                });
                            } else {
                                this.setState({
                                    outcomeText: t('Your answer is not correct. The correct answer is') + ' ' + correctAnswers.slice(0, -2),
                                });
                            }
                        }
                    }
                }
                if (this.state.tab <= this.state.questionsFinished) {
                    // Question already answered
                    this.setState({
                        buttonText: t('Next question'),
                        buttonNextQuestion: true,
                        buttonDisabled: false
                    });
                } else {
                    if (value < this.state.questionsFinished) {
                        this.setState({ buttonDisabled: true });
                    } else {
                        if (pos || pos === 0) {
                            this.setState({buttonDisabled: false,});
                        } else {
                            this.setState({
                                buttonDisabled: true,
                                outcomeText: null,
                            });
                        }
                    }
                }
            }
        );
    };

    clearRefsStyle = () => {
        const { tab } = this.state;
        for (let key in this.refs) {
            if (this.refs.hasOwnProperty(key)) {
                let element = this.refs[key];

                if (element.innerHTML) {
                    element.style.backgroundColor = '#F5F5F5';
                }
            }
        }
        for (
            let i = 0;
            this.props.externalToolboxAssign.toolbox.toolbox.questions[tab]
                .possibleAnswers.length !== i;
            i++
        ) {
            this.refs['divAnswerBoxChecked' + i].style.visibility = 'hidden';
            this.refs['divAnswerBox' + i].style.visibility = 'visible';
        }
    };

    handleAnswerClick = (selected) => {
        const { tab } = this.state;
        console.log('XXX handleAnswerClick: state: ', this.state);

        if ((this.state.questionsFinished + 1) === tab && !this.state.buttonNextQuestion) {
            let newArray = this.state.selectedAnswers.slice();

            // Single response
            if (
                this.props.externalToolboxAssign.toolbox.toolbox.questions[tab]
                    .type === 'singleResponse'
            ) {
                newArray[tab] = [selected];

                this.setState(
                    {
                        selectedAnswers: newArray,
                    },
                    () => {
                        this.clearRefsStyle();
                        this.refs['divAnswer' + selected].style.backgroundColor = '#D7D7D7';
                        this.refs['divAnswerBox' + selected].style.visibility = 'hidden';
                        this.refs['divAnswerBoxChecked' + selected].style.visibility = 'visible';
                        this.setState({ buttonDisabled: false,});
                    }
                );
            } else {
                // Multi Response
                // Empty array for this question
                if (!newArray[tab]) {
                    newArray[tab] = [];
                    newArray[tab].push(selected);

                    this.setState(
                        {
                            selectedAnswers: newArray,
                        },
                        () => {
                            this.refs['divAnswer' + selected].style.backgroundColor = '#D7D7D7';
                            this.refs['divAnswerBox' + selected].style.visibility = 'hidden';
                            this.refs['divAnswerBoxChecked' + selected].style.visibility = 'visible';
                            this.setState({buttonDisabled: false,});
                        }
                    );
                } else {
                    let doPush = true;
                    let externalElement = 0;

                    newArray[tab].forEach(function (element) {
                        if (element === selected) {
                            doPush = false;

                            let index = newArray[tab].indexOf(element);
                            if (index > -1) {
                                newArray[tab].splice(index, 1);
                                externalElement = element;
                            }

                            if (newArray[tab].length === 0) {
                                delete newArray[tab];
                            }
                        }
                    });

                    if (doPush) {
                        newArray[tab].push(selected);
                    } else {
                        this.refs['divAnswer' + externalElement].style.backgroundColor = '#F5F5F5';
                        this.refs['divAnswerBox' + selected].style.visibility = 'visible';
                        this.refs['divAnswerBoxChecked' + selected].style.visibility = 'hidden';

                        if (!newArray[tab]) {
                            this.setState({buttonDisabled: true,});
                        }
                    }

                    this.setState(
                        {
                            selectedAnswers: newArray,
                        },
                        () => {
                            if (doPush) {
                                this.refs['divAnswer' + selected].style.backgroundColor = '#D7D7D7';
                                this.refs['divAnswerBox' + selected].style.visibility = 'hidden';
                                this.refs['divAnswerBoxChecked' + selected].style.visibility = 'visible';

                                this.setState({buttonDisabled: false,});
                            }
                        }
                    );
                }
            }
        }
    };

    handlePageBack = () => {
        this.setState({
            stepIndex: this.state.stepIndex - 1,
        });
    };

    handleButtonAnswer = () => {
        const { t } = this.props;
        if (this.state.buttonNextQuestion) {
            // Handle Next Question
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            if (this.state.tab < (this.state.questionsFinished)) {
                // Handle user on previous tab
                // If user is on an already answered tab only increment the tab
                this.handleTabChange(undefined, (this.state.tab +1));
            } else {
                if (this.props.externalToolboxAssign.toolbox.toolbox.questions.length ===this.state.questionsFinished + 1) {
                    this.setState({stepIndex: 2,});
                } else {
                    this.setState({
                        tab: this.state.tab + 1,
                        buttonNextQuestion: false,
                        buttonText: t('Answer'),
                        buttonDisabled: true,
                        outcomeText: '',
                    });

                    if (this.state.questionsFinished < this.props.externalToolboxAssign.toolbox.toolbox.questions.length) {
                        localStorage.setItem('answers' + this.props.match.params.toolboxId,JSON.stringify(this.state.selectedAnswers));
                        localStorage.setItem('questionsfinished' + this.props.match.params.toolboxId,JSON.stringify(this.state.tab));
                    }
                }
            }


        } else {
            // Handle Answer Question
            const { tab } = this.state;
            const question = this.props.externalToolboxAssign.toolbox.toolbox.questions[tab];
            const selected = this.state.selectedAnswers[tab];

            window.scrollTo(0, document.body.scrollHeight);

            for (var i = 0; i !== question.possibleAnswers.length; i++) {
                this.refs['divAnswerIcon' + i].style.visibility = 'visible';
                this.refs['divAnswerBox' + i].style.visibility = 'hidden';
                this.refs['divAnswerBoxChecked' + i].style.visibility =
                    'hidden';
            }

            this.setState({questionsFinished: tab,});

            // Single response
            if (this.props.externalToolboxAssign.toolbox.toolbox.questions[tab].type === 'singleResponse') {
                if (selected[0] === question.correctAnswers[0]) {
                    //this.paintCorrectAnswer(selected);

                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({outcomeText: t('Your answer is correct.'),});
                } else {
                    this.refs['outcomeText'].style.color = 'red';
                    this.setState({
                        outcomeText: t('Unfortunately, your answer is incorrect. The correct answer is') +String.fromCharCode(65 + question.correctAnswers[0]) + '.',});
                }
            } else {
                // Multiresponse
                let isAllCorrect = true;

                selected.sort(function (a, b) {
                    return a - b;
                });

                if (selected.length !== question.correctAnswers.length) {
                    isAllCorrect = false;
                }

                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] !== question.correctAnswers[i]) {
                        isAllCorrect = false;
                    }
                }

                if (isAllCorrect) {
                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({outcomeText: t('Your answer is correct.'),});
                } else {
                    let correctAnswers = '';
                    question.correctAnswers.forEach((element) => {
                        correctAnswers += String.fromCharCode(65 + element) + ', ';
                    });

                    this.refs['outcomeText'].style.color = 'red';
                    if (correctAnswers.length > 3) {
                        this.setState({
                            outcomeText: t('Your answer is incorrect. The correct answers are:') + correctAnswers.slice(0, -2),
                        });
                    } else {
                        this.setState({
                            outcomeText: t('Unfortunately, your answer is incorrect. The correct answer is') + correctAnswers.slice(0, -2),
                        });
                    }
                }
            }

            // Change button state
            if (this.props.externalToolboxAssign.toolbox.toolbox.questions.length === this.state.questionsFinished + 1) {
                localStorage.setItem('questionsfinished' + this.props.match.params.toolboxId,JSON.stringify(tab));
                localStorage.setItem('finished' + this.props.match.params.toolboxId,true);
                localStorage.setItem('answers' + this.props.match.params.toolboxId,JSON.stringify(this.state.selectedAnswers));
                this.setState({
                    buttonText: t('Complete self-test'),
                    buttonNextQuestion: true,
                });
            } else {
                this.setState({
                    buttonText: t('Next question'),
                    buttonNextQuestion: true,
                });
            }
        }
    };

    render() {
        const { externalToolboxAssign, toolbox, t } = this.props;
        const {
            stepIndex,
            tab,
            questionsFinished,
            buttonText,
            buttonDisabled,
        } = this.state;

        // window.$crisp.push(['do', 'chat:hide']);
        let tlbx = null;
        if (toolbox) {
            tlbx = toolbox;
        }
        if (externalToolboxAssign) {
            tlbx = externalToolboxAssign.toolbox;
        }

        if (!tlbx || (externalToolboxAssign && externalToolboxAssign.result)) {
            return (
                <div>
                    <div className={classes.paper} id="tbExternPaperFeedback">
                        {window.innerWidth >= 500 ? (
                            <div style={{ marginTop: '50px' }}>
                                <img
                                    src="/assets/images/vvw.png"
                                    alt={t('Veilig Vakwerk')}
                                    style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                />
                            </div>
                        ) : (
                            <div style={{ marginTop: '50px' }}>
                                <img
                                    src="/assets/images/vvw.png"
                                    alt={t('Veilig Vakwerk')}
                                    style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '100%',
                                    }}
                                />
                            </div>
                        )}
                        <p style={{ textAlign: 'center' }}>
                            <br />
                            {t('The toolbox has been sent successfully. Thanks for filling in!')}{' '}
                            <br /> <br /> {t('This page can now be closed.')}
                        </p>
                        <br />
                        <div style={{ width: '100px', margin: 'auto' }}>
                            <a href="https://www.facebook.com/VeiligVakwerk">
                                <img
                                    src="/assets/images/facebook.png"
                                    alt={t('Veilig Vakwerk')}
                                />
                            </a>
                            <a href="https://twitter.com/VeiligVakwerk">
                                <img
                                    src="/assets/images/twitter.png"
                                    alt={t('Veilig Vakwerk')}
                                    style={{ paddingLeft: '16px' }}
                                />
                            </a>
                            <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                <img
                                    src="/assets/images/linkedin.png"
                                    alt={t('Veilig Vakwerk')}
                                    style={{ paddingLeft: '16px' }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }

        const tabSize = tlbx.toolbox.questions.length > 5
                ? 100 / 5 : 100 / tlbx.toolbox.questions.length;
        let meetingDate = null;
        if (externalToolboxAssign && externalToolboxAssign.toolboxMeeting) {
            meetingDate = new Date(externalToolboxAssign.toolboxMeetingDate);
        }

        let subjects = [];
        if (
            this.props.externalToolboxAssign &&
            this.props.externalToolboxAssign.toolboxMeetingSubjects
        ) {
            this.props.externalToolboxAssign.toolboxMeetingSubjects.forEach(
                (subject) => {
                    if (subject.subject !== '') {
                        subjects.push(
                            <ListItem
                                style={{
                                    paddingBottom: 1,
                                    paddingLeft: 0,
                                    width: 200,
                                }}
                            >
                                &#8226; {subject.subject}
                            </ListItem>
                        );
                    }
                }
            );
        }

        return (
            <div className={classes.app}>
                <div style={{ visibility: 'hidden' }}>
                    {(document.getElementById('Root').style.minWidth = '128px')}
                    {(document.getElementById('Root').style.maxWidth = '768px')}
                    {(document.getElementById('Root').style.margin = '0 auto')}
                </div>

                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        {stepIndex === 1 || stepIndex === 2 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            variant="h1"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig Vakwerk')}
                            {/*window.innerWidth >= 768 ? "Veilig Vakwerk - " + this.props.toolbox.title : "Veilig Vakwerk"*/}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <AppModule className="appModule">
                    {stepIndex === 0 && (
                        <div className={classes.paper} id="tbExternPaperMain">
                            {/*<div style={{visibility: 'hidden'}}>{document.getElementById("html").style.backgroundColor = 'white'}</div>*/}
                            {/*Auteur: {toolbox.author}*/}

                            <div>
                                <span className="material-light-caption">
                                    {t('Author')}: {tlbx.author}
                                </span>
                            </div>

                            {externalToolboxAssign.toolboxMeeting ? (
                                <div className={classes.toolboxMeetingBox}>
                                    <Typography
                                        className={classes.toolboxMeetingBoxH3}
                                    >
                                        {t('Toolboxmeeting')}
                                    </Typography>

                                    <div className={classes.toolboxMeetingLeft}>
                                        {t('Date')}:
                                    </div>
                                    <div
                                        className={classes.toolboxMeetingRight}
                                    >
                                        {/*{(meetingDate.getUTCHours() < 10 ? '0' : '') + meetingDate.getUTCHours() + ":" + (meetingDate.getUTCMinutes() < 10 ? '0' : '') + meetingDate.getUTCMinutes()} uur*/}
                                        {moment(
                                            externalToolboxAssign.toolboxMeetingDate
                                        ).format(t('DD-MM-YYYY'))}
                                    </div>
                                    <div style={{ clear: 'both' }}></div>

                                    <div className={classes.toolboxMeetingLeft}>
                                        {t('Time of the day')}:
                                    </div>
                                    <div
                                        className={classes.toolboxMeetingRight}
                                    >
                                        {moment(
                                            externalToolboxAssign.toolboxMeetingDate
                                        ).format('HH:mm')}
                                        {/*{meetingDate.getDay() + " " + monthNames[meetingDate.getMonth()] + " " + meetingDate.getFullYear()} */}
                                    </div>
                                    <div style={{ clear: 'both' }}></div>

                                    <div className={classes.toolboxMeetingLeft}>
                                        {t('Location')}:
                                    </div>
                                    <div
                                        className={classes.toolboxMeetingRight}
                                        style={{ paddingBottom: '0px' }}
                                    >
                                        {
                                            externalToolboxAssign.toolboxMeetingLocation
                                        }
                                    </div>
                                    <div style={{ clear: 'both' }}></div>

                                    <div
                                        className={classes.toolboxMeetingLeft}
                                        style={{
                                            width: '100%',
                                            paddingBottom: '0px',
                                        }}
                                    >
                                        {t('Topics to be discussed')}:
                                    </div>
                                    <div
                                        className={classes.toolboxMeetingLeft}
                                        style={{
                                            paddingBottom: '20px',
                                            opacity: 1,
                                        }}
                                    >
                                        {subjects}
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            ) : (false)}

                            <hr />
                            {tlbx.title && (
                                <h1 style={{ wordWrap: 'break-word' }}>
                                    {tlbx.title}
                                </h1>
                            )}
                            {tlbx.toolbox.blocks.map((block) => {
                                return (
                                    <BlockPreview
                                        block={block}
                                        key={block.id}
                                    />
                                );
                            })}
                            <hr />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                disabled={
                                    meetingDate !== null
                                        ? meetingDate.getTime() >=
                                          new Date().getTime()
                                        : false
                                }
                                className={classes.button}
                                onClick={this.toSelfTest}
                                style={{ width: '100%', height: '25px' }}
                            >
                                {meetingDate !== null
                                    ? meetingDate.getTime() >=
                                      new Date().getTime()
                                        ? t('AVAILABLE SOON')
                                        : t('TO SELF-TEST')
                                    : t('TO SELF-TEST')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 1 && (
                        <div>
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = '#f5f5f5')
                                }
                            </div>
                            {tlbx.toolbox.questions.length === 0 && (
                                <p>{t('The questionnaire is empty')}</p>
                            )}
                            {/* {tlbx.toolbox.questions.length === 1 && this.state.questionsFinished && <p>De vragenlijst is leeg</p> } */}
                            {tlbx.toolbox.questions.length > 0 && (
                                <div>
                                    {tlbx.toolbox.questions.length > 5 ? (
                                        <Tabs
                                            scrollable
                                            value={tab}
                                            onChange={this.handleTabChange}
                                            className={classes.root}
                                            style={{ padding: '0' }}
                                        >
                                            {tlbx.toolbox.questions.map(
                                                (questionId, i) => {
                                                    return (
                                                        <Tab disabled={ !( questionsFinished >= i )}
                                                            style={{ width: tabSize + '%',}}
                                                            label={t('Question') +(i + 1)}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                    ) : (
                                        <Tabs
                                            value={tab}
                                            onChange={this.handleTabChange}
                                            className={classes.root}
                                            style={{ padding: '0' }}
                                        >
                                            {tlbx.toolbox.questions.map(
                                                (questionId, i) => {
                                                    return (
                                                        <Tab disabled={!(questionsFinished >=i)}
                                                            style={{width:tabSize +'%',}}
                                                            label={t('Question') +(i + 1)}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                    )}

                                    <Paper elevation={4}
                                        className={classes.paper}
                                        id="tbExternPaper"
                                    >
                                        {tlbx.toolbox.questions.map(
                                            (questionId, i) => {
                                                return (
                                                    tab === i && (
                                                        <div className={classes.root}>
                                                            {questionId.title}
                                                            {questionId.blocks[0] &&
                                                                questionId.blocks[0].type === 'photosBlock' &&
                                                                questionId.blocks[0].photosBlock &&questionId.blocks[0].photosBlock.photos &&
                                                                questionId.blocks[0].photosBlock.photos.length > 0 &&
                                                                questionId.blocks[0].photosBlock.photos.map((photo) => (
                                                                        <div style={{textAlign:'center',marginTop:'10px',}}>
                                                                            <img style={{maxWidth: 300,maxHeight: 300,}}
                                                                                src={photo.url}
                                                                                alt="foto"
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                            {questionId.possibleAnswers.map(
                                                                (answer, i) => (
                                                                    <div key={answer.id} className={classes.unselectedAnswer}
                                                                        onClick={() => this.handleAnswerClick(i)}
                                                                        ref={'divAnswer' + i}
                                                                    >
                                                                        <div ref={'divAnswerIcon' + i}
                                                                            style={
                                                                                this.state.questionsFinished !== undefined &&
                                                                                this.state.tab <= this.state.questionsFinished ? {visibility:'visible',}
                                                                                    : {visibility: 'hidden',}
                                                                            }
                                                                            align="right"
                                                                        >
                                                                            {questionId.correctAnswers.includes(i) ? (
                                                                                <CheckCircleIcon
                                                                                    style={{right:'52px', marginTop:'-12px', position:'absolute', color:'green',}}
                                                                                    align="right"
                                                                                />
                                                                            ) : (
                                                                                <CancelIcon style={{right:'52px',marginTop:'-12px',position:'absolute',color:'red',}}/>
                                                                            )}
                                                                        </div>
                                                                        {questionId.type === 'singleResponse' && (
                                                                            <div ref={'divAnswerBox' + i } align="right">
                                                                                <RadioUncheckedIcon
                                                                                    style={this.state.questionsFinished !== undefined &&
                                                                                        this.state.tab <= this.state.questionsFinished ?
                                                                                            { right:'52px',marginTop:'-12px',position:'absolute',visibility:'hidden', }
                                                                                            : { right:'52px',marginTop:'-12px',position:'absolute',}
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {questionId.type === 'singleResponse' && (
                                                                            <div ref={'divAnswerBoxChecked' + i }
                                                                                style={{ visibility: 'hidden',}}
                                                                                align="right"
                                                                            >
                                                                                <RadioCheckedIcon
                                                                                    style={{ right:'52px', marginTop: '-12px', position: 'absolute',}}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {questionId.type !== 'singleResponse' && (
                                                                            <div ref={'divAnswerBox' + i }
                                                                                align="right"
                                                                            >
                                                                                <CheckboxUncheckedIcon
                                                                                    style={
                                                                                        this.state.questionsFinished !== undefined &&
                                                                                        this.state.tab <= this.state.questionsFinished ? {
                                                                                                  right:'52px',marginTop:'-12px',
                                                                                                  position:'absolute',
                                                                                                  visibility:'hidden',
                                                                                              }
                                                                                            : { right:'52px', marginTop:'-12px', position:'absolute', }
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {questionId.type !== 'singleResponse' && (
                                                                            <div ref={ 'divAnswerBoxChecked' + i }
                                                                                style={{visibility:'hidden',}}
                                                                                align="right"
                                                                            >
                                                                                <CheckboxCheckedIcon
                                                                                    style={{right:'52px', marginTop: '-12px', position: 'absolute',}}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <b>
                                                                            Antwoord{' '}
                                                                            {String.fromCharCode(65 + i)}
                                                                        </b>
                                                                        <br />
                                                                        { answer.text }
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            }
                                        )}

                                        <div className={classes.root}>
                                            {
                                                <p className={classes.outcomeText} ref="outcomeText">
                                                    {this.state.outcomeText}
                                                </p>
                                            }
                                            <Button
                                                
                                                variant="contained"
                                                disabled={buttonDisabled}
                                                color="primary"
                                                className={classes.button}
                                                onClick={this.handleButtonAnswer}
                                                id="answerButton"
                                            >
                                                {buttonText}
                                            </Button>
                                        </div>
                                    </Paper>
                                </div>
                            )}
                        </div>
                    )}
                    {stepIndex === 2 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById('html').style.backgroundColor = 'white')
                                }
                            </div>
                            <h2 style={{ textAlign: 'center' }}>
                                {t('Complete toolbox')}
                            </h2>
                            <p>
                                {t('Thanks for filling in, finish the toolbox here. When you click Finish toolbox, this link will no longer be available. That is why you automatically receive the results as PDF.')}
                                <br /> <br />
                                {t('Prefer not to save results? Then click below to remove the check mark.')}{' '}
                            </p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.downloadPDF}
                                        onChange={this.handleCheckPDF()}
                                        value="downloadpdf"
                                    />
                                }
                                label={t('Save a pdf of the toolbox with my results')}
                            />
                            <br />
                            <br />
                            <p> {t('Did you notice anything about the toolbox? Enter your feedback here.')}</p>
                            {window.innerWidth > 768 ? (
                                <textarea
                                    name="feedbackfield"
                                    cols="120"
                                    rows="4"
                                    value={this.state.feedbackText}
                                    onChange={this.handleFormChange('text')}
                                    style={{ resize: 'none' }}
                                />
                            ) : (
                                <textarea
                                    name="feedbackfield"
                                    cols="35"
                                    rows="4"
                                    value={this.state.feedbackText}
                                    onChange={this.handleFormChange('text')}
                                    style={{ resize: 'none' }}
                                />
                            )}
                            <br />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.sendResults}
                                id="sendresultsButton"
                                style={{ marginTop: '20px' }}
                            >
                                {t('Complete toolbox')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                    {stepIndex === 3 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t( 'The toolbox has been sent successfully. Thanks for filling in!')}
                                <br /> <br />
                                {t('This page can now be closed.')}
                            </p>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img src="/assets/images/facebook.png" alt={t('Veilig Vakwerk')}/>
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img src="/assets/images/twitter.png" alt={t('Veilig Vakwerk')}
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img src="/assets/images/linkedin.png" alt={t('Veilig Vakwerk')} style={{ paddingLeft: '16px' }}/>
                                </a>
                            </div>
                        </div>
                    )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ToolboxExtern);
