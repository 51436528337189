// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import { bindActionCreators } from 'redux';
import { setCrumbPath, pushCrumbPath, editEmployee } from '../../../actions';
import EditIcon from '@mui/icons-material/Edit';

import EmployeeInformation from '../EmployeeInformation';
import AppModule from '../../AppModule/AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { IconButton } from '@mui/material';
import { push } from 'connected-react-router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classes from './EmployeeDetailView.module.scss';
import colors from "../../../lib/themes/theme.scss";
import i18n from 'i18next';
import getRoles from "../../../selectors/getRoles";



type Props = {
    match: any,
    employee?: any,
    fetchSectors: () => mixed,
    fetchCategories: () => mixed,
    fetchEmployees: () => mixed,
    setCrumbPath: (employeeName: string) => void,
};

type State = {
    tab: number,
};


const mapStateToProps = (store, props) => {
    const employees = store.entities.employees;
    const employeeId = props.match.params.employeeId;
    // const page = props.match.params.page;
    // const rowsPerPage = props.match.params.rowsPerPage;

    const employee = employees.byId[employeeId];


    return {
        employee: employee,
        employees: employees,
        editemployee: store.drafts.employees.editemployee,
        allSectors: store.entities.sectors,
        allCategories: store.entities.categories,
        roles: getRoles(store),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editEmployee: bindActionCreators(editEmployee, dispatch),
        setCrumbPath: (crumbs) => {
            dispatch(setCrumbPath(crumbs));
        },
        pushCrumbPath: (employeeName: string, id: number) => {
            dispatch(pushCrumbPath({crumb: { name: employeeName, link: `/medewerker/details/${id}`}}));
        },
        goToDialog: () => {
            dispatch(push('#dialog'));
        },
    };
};

class EmployeeDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };



    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };


    componentDidMount() {
        const tabs = ['#informatie'];

        if (this.props.location.hash) {
            const hash = this.props.location.hash;

            this.setState({
                tab: ~tabs.indexOf(hash) ? tabs.indexOf(hash) : 0,
            });
        }

        if (this.props.history && this.props.history.action === 'POP') {
            // Page was refreshed / Reloaded
            this.props.setCrumbPath({ title: i18n.t('Employees'), crumbs: [ { name: i18n.t('Employees'), link: '/medewerkers' } ] });
        }


    }

    editEmployee = () => {
        this.props.editEmployee(this.props.employee);
        this.props.goToDialog();
        this.setState({ showDetailDialog: true });
    };

    render() {
        const { employee, editemployee } = this.props;
        const { tab } = this.state;

        const isAdmin = this.props.roles && this.props.roles.includes('ADMIN') ? true : false;

        const editAction = (
            <IconButton
                onClick={this.editEmployee}
                className={classes.button}
                key="edit"
            >
                <EditIcon />
            </IconButton>
        );
        const tableActionHeader = (
            <TableActionHeader
                editAction={
                    employee && tab === 0 && (employee.importType !== 'IMPORT' || isAdmin)
                        ? editAction
                        : undefined
                }
            />
        );

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{'& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label="INFO" href="#informatie" sx={{color: colors.mainTabsColor }}/>
                </Tabs>
                <AppModule loading={false} hasTabs prepend={tableActionHeader}>
                    {tab === 0 && (
                        <EmployeeInformation
                            employee={employee}
                            editemployee={editemployee}
                        />
                    )}
                </AppModule>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeDetailView);
